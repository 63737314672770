import React, { useEffect, useRef } from "react"

import { SanityFile } from "@graphql-types"

interface Props {
  data: SanityFile
}

const DownloadBlock = ({ data }: Props) => {
  if (data.asset == null) return null

  const { asset } = data

  const downloadBtn = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (asset && downloadBtn.current) {
      downloadBtn.current.click()
    }
  }, [])

  return (
    <a
      href={`${asset?.url}?dl=${asset?.originalFilename}`}
      download
      ref={downloadBtn}
      target="_blank"
      style={{ display: "none" }}
    >
      Download
    </a>
  )
}

export default DownloadBlock
