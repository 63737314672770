import React from "react"
import styled from "styled-components"

import CTA from "@components/shared/sub/cta"
import { SanityCtas } from "@graphql-types"

interface Props {
  data: SanityCtas
}
const ResidentialCtaStyled = styled.div`
  a {
    @media only screen and (max-width: 400px) {
      font-size: 1.3rem !important;
    }
  }
`

function Ctas({ data }: Props) {
  if (data?.ctas == null) return null

  const { ctas } = data
  return (
    <ResidentialCtaStyled>
      <div>
        {ctas.map(cta => {
          if (cta == null) return null

          return <CTA key={cta._key} {...cta} />
        })}
      </div>
    </ResidentialCtaStyled>
  )
}

interface Props {
  data: SanityCtas
}

export default Ctas
