import { colors, TABLET_BREAKPOINT } from "@util/constants"
import styled from "styled-components"

export default styled.div<{ hasAuthor?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  a {
    height: auto;
  }
  .gatsby-image-wrapper {
    min-height: 230px;
    margin-bottom: 1.5rem;
    border-radius: 0.4rem;
    overflow: hidden;

    & > div {
      padding-bottom: 66% !important;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  span {
    display: block;
  }

  .author {
    margin: 0;
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
    font-family: medium;
    color: var(--red);
    margin: 20px 0 0 0;
  }

  p {
    color: ${colors.maroon};
    margin: 15px 0 40px 0;
  }
  p.category {
    margin: 0;
    color: white;
  }

  a.blog-link {
    display: flex;
    align-items: center;
    width: fit-content;
    justify-self: flex-end;
    margin-top: auto;

    :hover {
      opacity: 0.7;
      color: var(--red);
    }

    svg {
      position: relative;
      left: 2px;
      transition: left 0.2s ease-out;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: auto;
  }
`
