import React from "react"
import ReactPlayer from "react-player/youtube"
import styled from "styled-components"

interface Props {
  youTubeId?: string
}

function Video({ youTubeId }: Props) {
  return (
    <VideoWrapper>
      <ReactPlayer url={youTubeId} width="100%" controls />
    </VideoWrapper>
  )
}

export default Video

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  iframe {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`
