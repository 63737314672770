import { Maybe, SanityHeader } from "@graphql-types";
import React, { ReactNode } from "react";
import {
  colors,
  fontSizes,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import styled from "styled-components";
import { Color } from "@util/types";
import ContentBlock from "@components/shared/sub/contentBlock";
import Image from "./image";

interface Props {
  title?: Maybe<string> | undefined;
  downloadUrl?: Maybe<string> | undefined;
  buttonText?: Maybe<string> | undefined;
  headerData?: Maybe<SanityHeader> | undefined;
  children?: ReactNode;
}

const HeaderWrapper = styled.section<{
  backgroundColor?: Color;
  noHeaderIcon?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ noHeaderIcon }) => noHeaderIcon && `background: var(--red) ;`};

  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`};

  height: 400px;

  .container {
    max-width: 1380px;
  }

  h1 {
    color: white;
    font-size: ${fontSizes.p.default}px;
  }

  .img {
    width: 365px;
    height: 300px;
    object-fit: cover;
    position: absolute !important;
    right: 0px;
    top: 30px;
    opacity: 1;
    border-radius: 0 0 0 50px;
    z-index: 0;
    @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
      width: 35%;
      height: 220px;

      z-index: 0;
      top: 10px;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 50%;
      height: 210px;
    }
  }

  .title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 0px;
  }
  .header-description {
    z-index: 1;
    position: relative;
    width: 75%;
    h1,
    h2,
    h3,
    h4,
    p {
      color: ${colors.white};
    }
    h2,
    .title {
      font-size: 40px;
      line-height: 48px;
    }
    p {
      width: 85%;
    }
  }

  a {
    color: white;
    text-decoration: underline;
    font-family: "medium";
    :hover {
      color: white;
      opacity: 0.7;
    }

    svg {
      position: relative;
      top: 2px;
      left: 0px;
      transition: left 0.2s ease-out;
    }

    &:hover {
      svg {
        left: -6px;
      }
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 500px;
    .container {
      margin-top: 160px;
    }

    .title {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobile + 7}px;
    }

    .header-description {
      width: 100%;
      h1,
      h2,
      h3,
      h4,
      p {
        color: ${colors.white};
      }
      h2 {
        font-size: ${fontSizes.h2.mobile}px;
        line-height: ${fontSizes.h2.mobile + 7}px;
      }
      p {
        width: 100%;
      }
    }
  }
`;

const Header: React.FC<Props> = ({ title, downloadUrl, buttonText, headerData, children }) => {
  const hasNoIcon = headerData?.headerIcon == null;

  const hasHeaderText =
    headerData &&
    headerData.headerText &&
    headerData.headerText._rawContent[0].children[0] &&
    headerData.headerText._rawContent[0].children[0].text !== "";

  console.log(headerData?.headerIcon);

  return (
    <HeaderWrapper
      backgroundColor={(headerData?.headerColor as Color) ?? "maroon"}
      noHeaderIcon={hasNoIcon}
    >
      {headerData?.headerIcon && <Image data={headerData?.headerIcon} className="img" />}
      {children ? (
        children
      ) : (
        <div className="container">
          {title && !hasHeaderText && <h1 className="title">{title}</h1>}

          {hasHeaderText && (
            <div className="header-description">
              <ContentBlock blocks={headerData?.headerText?._rawContent} />
            </div>
          )}

          {downloadUrl && (
            <a href={`${downloadUrl}?dl=`} download target="_blank">
              {buttonText ?? "Download not starting? Click here"}
            </a>
          )}
        </div>
      )}
    </HeaderWrapper>
  );
};

export default Header;
