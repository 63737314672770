import {
  SanityBlogsBlock,
  SanityCalculatorsBlock,
  SanityCtas,
  SanityFormBlock,
  SanityGuideBlock,
  SanityHeader,
  SanityMyBlock,
  SanityNewsletterBlock,
  SanityRelatedBlogs,
  SanityServicesBrief,
  SanityTextAndButtonsBlock,
  SanityVideoSlider,
} from "@graphql-types"
import {
  assets,
  colors,
  colorsRGB,
  fontFamilies,
  fontWeights,
} from "./constants"

export type Asset = keyof typeof assets
export type Color = keyof typeof colors
export type ColorRGB = keyof typeof colorsRGB
export type FontWeights = keyof typeof fontWeights
export type FontFamilies = keyof typeof fontFamilies
export type AssetType = typeof assets

export function isGuideBlock(data: any): data is SanityGuideBlock {
  return data._type === "guideBlock"
}

export function isCalculatorsBlock(data: any): data is SanityCalculatorsBlock {
  return data._type === "calculatorsBlock"
}
export function isBlogsBlock(data: any): data is SanityBlogsBlock {
  return data._type === "blogsBlock"
}

export function isHeader(data: any): data is SanityHeader {
  return data._type === "header"
}

export function isFormBlock(data: any): data is SanityFormBlock {
  return data._type === "formBlock"
}

export function isCtas(data: any): data is SanityCtas {
  return data._type === "ctas"
}

export function isMyBlock(data: any): data is SanityMyBlock {
  return data._type === "myBlock"
}

export function isNewsletterBlock(data: any): data is SanityNewsletterBlock {
  return data._type === "newsletterBlock"
}

export function isServicesBrief(data: any): data is SanityServicesBrief {
  return data._type === "servicesBrief"
}

export function isTextAndButtonsBlock(
  data: any
): data is SanityTextAndButtonsBlock {
  return data._type === "textAndButtonsBlock"
}

export function isRelatedBlogs(data: any): data is SanityRelatedBlogs {
  return data._type === "relatedBlogs"
}

export function isVideoSlider(data: any): data is SanityVideoSlider {
  return data._type === "videoSlider"
}
