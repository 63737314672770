import {
  IPAD_PRO_BREAKPOINT,
  colors,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
} from "@util/constants"
import { Container, AbsoluteContainer } from "@util/standard"
import { Color } from "@util/types"
import styled from "styled-components"

export const Wrapper = styled(Container)<{ isRowLayout?: boolean }>`
  width: 96%;
  max-width: 1400px;
  margin: 100px auto;

  ${({ isRowLayout }) =>
    isRowLayout &&
    `
  width: 80%;
  flex-direction: column;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column;
    width: 100%;
  }
`

export const BriefWrapper = styled(Container)<{
  briefCount: number
  isLast?: boolean
  isRowLayout?: boolean
  backgroundColor: Color
}>`
  position: relative;
  ${({ briefCount }) => briefCount && `width: calc(100% / ${briefCount});`};
  ${({ isLast }) => (isLast ? `margin: 0;` : `margin: 0 1% 0 0;`)};
  border-radius: 10px;
  ${({ backgroundColor }) =>
    backgroundColor && `background: ${colors[backgroundColor]};`};
  ${({ isRowLayout, isLast }) =>
    isRowLayout &&
    `
    width: 100%;
    flex-direction: column;
    ${isLast ? `margin: 0;` : `margin: 0 0 20px 0;`};
  `}

  .tag {
    background: ${colors.white};
    width: max-content;
    padding: 7px 10px;
    border-radius: 3px;
    color: ${props =>
      props.backgroundColor ? colors[props.backgroundColor] : colors.maroon};
    p {
      margin: 0;
    }
    margin-bottom: 30px;
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 82%;
    ${({ isLast }) =>
      isLast ? `margin: 0 auto;` : `margin: 0 auto 20px auto;`};
  }
`

export const TextWrapper = styled.div<{ isRowLayout?: boolean }>`
  padding: 24% 15% 50% 15%;
  color: ${colors.white};

  h3 {
    color: ${colors.white};
    font-size: 24px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  ${({ isRowLayout }) =>
    isRowLayout &&
    `padding: 10%;
    h3,
    p {
      max-width: 450px;
    }`}

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    padding: 30% 25px;
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    padding: 10%;
    h3,
    p {
      max-width: 450px;
    }
  }
`

export const StyledAbsoluteContainer = styled(AbsoluteContainer)<{
  isRowLayout?: boolean
}>`
  bottom: -5px;

  ${({ isRowLayout }) =>
    isRowLayout &&
    `right: 15%; 
    img {
      width: 184px;
    }`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    right: 15%;
    img {
      width: 120px;
    }
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
    }
  }
`
