import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Carousel, ModalBox, Video } from "@global"

import { Maybe, SanityVideo, SanityVideoSlider } from "@graphql-types"
import {
  colors,
  colorsRGB,
  LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants"
import { Swiper, SwiperSlide } from "swiper/react"
import { FluidObject } from "gatsby-image"
import { ArrowMaroon, PlayButton } from "@util/assets"
import { ContentHubQueries } from "@components/contentHub/contentHubContent"
import { useApplyFilterSearch } from "@util/hooks"

interface Props {
  data: SanityVideoSlider
  searchState: ContentHubQueries
}

function VideoSlider({ data, searchState }: Props) {
  if (data?.videos == null) return null
  const [videoOpen, setVideoOpen] = useState(false)
  const [controller, setController] = useState<Swiper>(null)

  const [currentVideoUrl, setCurrentVideoUrl] = useState<
    Maybe<string> | undefined
  >(null)

  const { title, videos } = data

  const handleSlideChange = () => {
    if (controller) {
      controller.slideNext()
    }
  }

  const filterData = useApplyFilterSearch(videos, searchState)

  if (filterData == null || filterData.length === 0) return null

  return (
    <>
      <VideoSliderWrapper id="videos" videoCount={filterData.length}>
        <h2>{title}</h2>
        <div className="video-wrapper">
          <div className="slider">
            {filterData && (
              <Carousel
                onSwiper={swiper => setController(swiper)}
                navigation={{ nextEl: `#video-slider-next-${data._key}` }}
                spaceBetween={20}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                  },
                  1000: {
                    slidesPerView: 2.5,
                  },
                }}
              >
                {filterData.map((video: SanityVideo | null) => {
                  if (video == null) return null

                  const { title, thumbnail, youTubeId } = video
                  return (
                    <SwiperSlide key={video._key}>
                      <VideoWrapper>
                        {thumbnail && (
                          <div className="video-thumbnail">
                            <Img
                              fluid={thumbnail?.asset?.fluid as FluidObject}
                            />
                          </div>
                        )}
                        <div
                          className="play-button"
                          onClick={() => {
                            setVideoOpen(true)
                            setCurrentVideoUrl(youTubeId)
                          }}
                        >
                          <PlayButton />
                        </div>
                        {<h3>{title}</h3>}
                      </VideoWrapper>
                    </SwiperSlide>
                  )
                })}
              </Carousel>
            )}
            {filterData && filterData.length > 1 && (
              <div
                onClick={handleSlideChange}
                className="nav-arrow"
                id={`video-slider-next-${data._key}`}
              >
                <ArrowMaroon />
              </div>
            )}
          </div>
        </div>
      </VideoSliderWrapper>
      <ModalBox isOpen={videoOpen} setIsOpen={setVideoOpen}>
        {currentVideoUrl && <Video youTubeId={currentVideoUrl} />}
      </ModalBox>
    </>
  )
}

export default VideoSlider

const VideoSliderWrapper = styled.div<{ videoCount?: number }>`
  width: 100%;
  margin-bottom: 120px;

  .video-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .slider {
      position: relative;
      width: 90%;
      max-width: 1641px;
    }
  }

  h2 {
    color: ${colors.maroon};
    font-size: 40px;
    margin: auto;
    margin-bottom: 60px;
    max-width: 1380px;
    width: 80%;
  }

  .nav-arrow {
    ${({ videoCount }) => videoCount && videoCount < 3 && "display: none"};
    width: 41px;
    height: 41px;
    position: absolute;
    background: ${colors.cream};
    right: 120px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border-radius: 3px;
    cursor: pointer;
    svg {
      margin: auto;
      height: 41px;
      width: 15px;
      display: block;
      transform: rotate(90deg);
    }
    &:hover {
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .video-wrapper {
      width: 100%;
      .slider {
        margin: auto;
      }
      justify-content: flex-start;
    }
    margin-bottom: 60px;

    h2 {
      color: ${colors.maroon};
      font-size: 28px;
      margin-bottom: 30px;
      width: 90%;
    }
    .nav-arrow {
      ${({ videoCount }) => videoCount && videoCount > 1 && "display: block"};

      right: 20px;
      opacity: 0.6;
    }
  }
`

const VideoWrapper = styled.div`
  position: relative;
  background: ${colorsRGB.black(1)};
  height: 340px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  img {
    object-fit: cover;
  }
  h3 {
    color: ${colors.white};
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: 85%;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }
  .play-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 270px;
    .play-button {
      width: max-content;
      top: 100px;
      svg {
        width: 70px;
      }
    }
  }
`
