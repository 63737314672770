import { ContentHubQueries } from "@components/contentHub/contentHubContent"
import {
  Maybe,
  SanityGuide,
  SanityVideo,
} from "@graphql-types"
import { RefObject, useEffect, useRef, useState } from "react"
import { isBrowser } from "./helpers"

export const useGetIP = () => {
  const [ipAddress, setIpAddress] = useState<any>()

  useEffect(() => {
    const getIp = async () => {
      const fetchIP = await (await fetch("https://ipapi.co/json/")).json()
      return fetchIP
    }

    getIp().then(api => setIpAddress(api))
  }, [])

  return ipAddress
}

export function useOnClickOutside(
  ref: RefObject<any>,
  handler: (args: any) => void
) {
  useEffect(() => {
    if (!isBrowser()) return
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}

export function useApplyFilterSearch(
  items: Maybe<Maybe<SanityGuide | SanityVideo>[]> | undefined,
  searchState: ContentHubQueries
) {
  const [foundItems, setFoundItems] = useState<
    Maybe<Maybe<SanityGuide>[]> | undefined
  >([])

  const setFilteredData = () => {
    const filteredData = items?.filter(item => {
      const { searchQuery, filters } = searchState

      if (item == null) return null
      if (searchQuery === "" && filters.length === 0) return item

      const searchQueryMatches =
        item.title &&
        item.title?.toLowerCase().includes(searchQuery.toLowerCase())

      const filtersMatch = filters.some(filter => {
        if (item.categories == null || item.categories.length === 0) return null
        return item.categories.some(data => data?._id === filter)
      })

      if (filtersMatch && searchQuery === "") return item
      if (filters.length === 0 && searchQueryMatches) return item
      if (filtersMatch && searchQueryMatches) return item

      return null
    })

    return filteredData
  }

  useEffect(() => {
    const items = setFilteredData()
    if (items) {
      setFoundItems(items)
    }
  }, [searchState])

  return foundItems
}

export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any
) {
  if (!isBrowser()) return
  const element = elementToListen ?? window

  const savedHandler = useRef<typeof handler>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)

    return () => element.removeEventListener(eventName, eventListener)
  }, [eventName, element])
}

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(
  next: T,
  compare: (prev: T, next: T) => T
) {
  const previousRef = useRef<T>(next)
  const previous = previousRef.current

  const isEqual = compare(previous, next)

  useEffect(() => {
    if (previousRef.current == null) {
      return
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next
    }
  })

  return isEqual ? previous : next
}
