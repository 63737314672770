import React from "react"

import {
  Maybe,
} from "@graphql-types"
import styled from "styled-components"
import {
  isCtas,
  isFormBlock,
  isHeader,
  isMyBlock,
  isNewsletterBlock,
  isRelatedBlogs,
  isServicesBrief,
  isTextAndButtonsBlock,
} from "@util/types"
import {
  Ctas,
  FormBlock,
  Header,
  NewsletterBlock,
  ServicesBrief,
  RelatedBlogs,
  TextButtons,
} from "@global"
import ContentBlock from "@components/shared/sub/contentBlock"

interface Props {
  data: any;
  pageTitle?: Maybe<string> | undefined
}

interface BlockProps {
  data: any;
  pageTitle?: Maybe<string> | undefined
}

export default function Sections({ data, pageTitle }: Props) {
  if (data == null) return null

  return (
    <>
      {data.map((item: any) => {
        if (item == null) return null

        return (
          <Section key={item._key}>
            <Block data={item} pageTitle={pageTitle} />
          </Section>
        )
      })}
    </>
  )
}

const Block = ({ data, pageTitle }: BlockProps) => {
  if (data == null) return null

  if (isHeader(data)) {
    return <Header title={pageTitle} headerData={data} />
  }

  if (isCtas(data)) {
    return <Ctas data={data} />
  }

  if (isFormBlock(data)) {
    return <FormBlock data={data} />
  }
  if (isMyBlock(data)) {
    const { _rawContent } = data
    if (_rawContent == null) return null
    return (
      <div className="container">
        <ContentBlock blocks={_rawContent} />
      </div>
    )
  }

  if (isNewsletterBlock(data)) {
    return <NewsletterBlock data={data} />
  }
  if (isServicesBrief(data)) {
    return <ServicesBrief data={data} isRowLayout />
  }
  if (isTextAndButtonsBlock(data)) {
    return <TextButtons data={data.content} />
  }
  if (isRelatedBlogs(data)) {
    return <RelatedBlogs data={data} />
  }
  return null
}

const Section = styled.section`
  margin-bottom: 120px;
`
