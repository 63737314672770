import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import { SanityFlightImage } from "@graphql-types";
import MyLink from "@components/shared/sub/myLink";

interface Props {
  data: SanityFlightImage;
  className?: string;
}

const Image = ({ data, className }: Props) => {
  if (!data) return null;
  const imageData = data.asset?.fluid as FluidObject;
  if (!imageData) return null;

  const link = data.hyperlink;

  const ImageRender = () => (
    <GatsbyImage
      fluid={imageData}
      className={className}
      itemProp="image"
      alt={data.altText ?? "image"}
    />
  );

  if (link) {
    return (
      <MyLink url={link} className={className}>
        <ImageRender />
      </MyLink>
    );
  }

  return <ImageRender />;
};

export default Image;
