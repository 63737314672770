import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AiOutlineRight } from "react-icons/ai";

import { SanityGuide } from "@graphql-types";
import { GuideShapeBlue, GuideShapeGreen, GuideShapePurple, GuideShapeTeal } from "@util/assets";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";

interface Props {
  data: SanityGuide;
}

function GuidePreview({ data }: Props) {
  if (data == null) return null;

  const { title, description, categories, slug } = data;

  const setCategoryShape = () => {
    if (categories == null) return null;

    const firstCategory = categories[0]?.CategoryName?.toLowerCase();

    if (firstCategory == null) return null;
    if (firstCategory.includes("business loans")) {
      return <GuideShapeBlue />;
    }
    if (firstCategory.includes("development finance")) {
      return <GuideShapeGreen />;
    }
    if (firstCategory.includes("home loans")) {
      return <GuideShapePurple />;
    }
    if (firstCategory.includes("development finance")) {
      return <GuideShapeGreen />;
    }
    if (firstCategory.includes("property investment")) {
      return <GuideShapeTeal />;
    }
    return null;
  };

  return (
    <Link to={`/${slug?.current}`}>
      <GuidePreviewWrapper className="guide-preview">
        <Container flexDirection="column" justifyContent="space-between">
          <p className="guide-label">GUIDE</p>
          <h3 className="guide-title">{title}</h3>
          <p className="guide-description">{description}</p>
          {setCategoryShape()}
          <AiOutlineRight className="icon-right" height="11px" />
        </Container>
      </GuidePreviewWrapper>
    </Link>
  );
}

export default GuidePreview;

const GuidePreviewWrapper = styled(Container)`
  position: relative;
  background: ${colors.white};
  padding: 40px;
  height: 100%;
  color: ${colors.maroon};
  .guide-label {
    margin-bottom: 130px;
    font-size: 13px;
  }

  .guide-title {
    font-size: 25px;
    line-height: 28px;
    max-width: 270px;
    color: ${colors.maroon};
  }
  .guide-description {
    width: 368px;
    font-size: 13px;
  }

  .svg-shape {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  svg.icon-right {
    font-size: 24px;
    position: absolute;

    bottom: 40px;
    right: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 30px;
    .guide-label {
      margin-bottom: 90px;
    }
    .guide-title {
      font-size: 20px;
      line-height: 24px;
    }
    .svg-shape {
      width: 99px;
      height: max-content;
    }
    svg.icon-right {
      bottom: 30px;
      right: 20px;
    }

    .guide-description {
      width: 250px;
      font-size: 13px;
    }
  }
`;
