import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { assets, colors, TABLET_BREAKPOINT } from "@util/constants";
import { Container, P } from "@util/standard";
import { Color } from "@util/types";
import { CheckedIcon } from "@util/assets";
import { Maybe } from "@graphql-types";

interface Props {
  color: Maybe<Color> | undefined;
  label: Maybe<string> | undefined;
  onChange?: () => void;
  checkOverride?: boolean;
  padding?: string;
}

const HiddenInput = styled.input`
  /* HIDES DEFAULT CHECKBOX OFF SCREEN WHILE KEEPING IT READABLE FOR E-READERS*/
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const Wrapper = styled(Container)<{ color?: Maybe<Color> | undefined }>`
  /* &:hover {
    ${({ color }) => color && `background: ${colors[color]};`};
  } */

  p {
    color: ${colors.white};
    ${({ color }) => color && `background: ${colors[color]};`};
    width: max-content;
    padding: 7px 10px;
    border-radius: 3px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    &:hover {
      background: transparent;
    }
  }
`;

const CheckBoxWrapper = styled.div<{
  color?: Maybe<Color> | undefined;
  checked?: boolean;
}>`
  display: flex;
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
  border: 1px solid black;
  ${({ checked }) => (checked ? `background: ${colors.maroon};` : `background: transparent;`)};
  position: relative;
  margin: auto 0;
  margin-right: 8px;
`;

function CheckBox({ label, onChange, color, checkOverride, padding }: Props) {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
    if (onChange) {
      onChange();
    }
  };

  useEffect(() => {
    if (checkOverride) {
      setChecked(true);
      return;
    }
    setChecked(false);
    return;
  }, [checkOverride]);

  return (
    <Wrapper
      color={color ?? "maroon"}
      onClick={handleClick}
      cursor="pointer"
      padding={padding ?? "0px 0px 10px 0px"}
    >
      <HiddenInput
        aria-label={label ?? "checkbox-option"}
        type="checkbox"
        checked={checked}
        readOnly
      />
      <CheckBoxWrapper color={color ?? "maroon"} checked={checked}>
        {checked && <CheckedIcon />}
      </CheckBoxWrapper>

      <p>{label}</p>
    </Wrapper>
  );
}

export default CheckBox;
