import React from "react";
import styled from "styled-components";

import { SanityIframeEmbed } from "@graphql-types";
import { MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityIframeEmbed;
}
export default function IframeEmbed({ data }: Props) {
  if (!data) return null;
  const { desktopIframe, mobileIframe } = data;

  return (
    <IframeEmbedContainer className="iframe-embed">
      {desktopIframe?.code && (
        <div className="desktop" dangerouslySetInnerHTML={{ __html: desktopIframe.code }} />
      )}
      {mobileIframe?.code && (
        <div className="mobile" dangerouslySetInnerHTML={{ __html: mobileIframe.code }} />
      )}
    </IframeEmbedContainer>
  );
}

const IframeEmbedContainer = styled.div`
  .desktop {
    display: block;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      display: block;
    }
  }
`;
