import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Maybe, SanityCategory, SanitySeo, SanitySlug } from "@graphql-types";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Color } from "@util/types";
import { CalculatorIcon } from "@util/assets";

interface Props {
  data: {
    title: Maybe<string> | undefined;
    categories: Maybe<Maybe<SanityCategory>[]> | undefined;
    slug?: Maybe<SanitySlug> | undefined;
    description: Maybe<string> | undefined;
    seo: Maybe<SanitySeo> | undefined;
  };
}

function CalculatorPreview({ data }: Props) {
  if (data == null) return null;
  const { categories, title, description, seo } = data;

  const firstCategoryColor = (categories && categories[0]?.colorList) ?? "maroon";

  return (
    <Link to={seo?.slug?.current ?? "/"}>
      <PreviewWrapper className="calculator-preview" backgroundColor={firstCategoryColor as Color}>
        <Container flexDirection="column" justifyContent="space-between">
          <p className="guide-label">CALCULATOR</p>
          <h3 className="guide-title">{title}</h3>
          <p className="guide-description">{description}</p>
        </Container>
        <CalculatorIcon />
      </PreviewWrapper>
    </Link>
  );
}

export default CalculatorPreview;

const PreviewWrapper = styled(Container)`
  position: relative;

  padding: 40px;
  height: 100%;
  color: ${colors.white};
  .guide-label {
    margin-bottom: 130px;
    font-size: 13px;
  }

  .guide-title {
    font-size: 25px;
    line-height: 28px;
    max-width: 280px;
    color: ${colors.white};
  }
  .guide-description {
    width: 368px;
    font-size: 13px;
  }

  svg {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 30px;
    .guide-label {
      margin-bottom: 90px;
    }
    .guide-title {
      font-size: 20px;
      line-height: 24px;
    }
    svg {
      width: 99px;
      height: max-content;
    }
    .guide-description {
      width: 250px;
      font-size: 13px;
    }

    svg {
      width: 24px;
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
`;
