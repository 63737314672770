import React from "react";

import { Maybe, SanityTextAndButton } from "@graphql-types";
import styled from "styled-components";
import MyLink from "@components/shared/sub/myLink";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Color } from "@util/types";
import { Container } from "@util/standard";

interface Props {
  data: Maybe<Maybe<SanityTextAndButton>[]> | undefined;
}

const TextButtonBlocks = styled.div<{ blockCount: number }>`
  max-width: 1149px;
  margin: auto;
  .container {
    display: grid;

    ${({ blockCount }) => blockCount && `grid-template-columns: repeat(${blockCount}, 1fr);`};
    grid-gap: 2rem;
    margin: 4rem auto;
    width: 100%;

    @media only screen and (max-width: 1050px) {
      grid-template-columns: 1fr;
      width: 80%;
    }
  }
`;

const TextAndButtonBlock = styled.div<{ color?: Color }>`
  ${({ color }) => color && `background: ${colors[color]};`};
  color: ${colors.white};
  border-radius: 10px;

  p {
    margin: auto 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  }
`;

function TextButtons({ data }: Props) {
  if (data == null) return null;

  const isSingleItem = data.length === 1;

  return (
    <TextButtonBlocks blockCount={data.length} className="text-button-block">
      <div className="container">
        {data.map(textButton => {
          if (textButton == null) return null;
          const { text, button, color } = textButton;
          return (
            <TextAndButtonBlock color={(color as Color) ?? "maroon"}>
              <Container
                justifyContent="space-between"
                flexDirection={isSingleItem ? "row" : "column"}
                tabletFlexDirection="column"
                height="100%"
                margin="auto"
                padding="40px 30px"
                gap="30px"
              >
                {text && <p>{text}</p>}
                {button?.url && (
                  <MyLink className="theme-btn block white" url={button.url}>
                    {button.linkText}
                  </MyLink>
                )}
              </Container>
            </TextAndButtonBlock>
          );
        })}
      </div>
    </TextButtonBlocks>
  );
}

export default TextButtons;
