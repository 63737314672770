import React from "react"

import MyLink from "../components/shared/sub/myLink"
import { SanityServicesBrief } from "@graphql-types"
import { Color } from "@util/types"
import {
  Wrapper,
  BriefWrapper,
  TextWrapper,
  StyledAbsoluteContainer,
} from "./global.styles"

interface Props {
  data: SanityServicesBrief
  isRowLayout?: boolean
}

function ServicesBrief({ data, isRowLayout }: Props) {
  if (data == null) return null

  const { Briefs } = data

  return (
    <>
      {Briefs && (
        <Wrapper isRowLayout={isRowLayout}>
          {Briefs.map((brief, index) => {
            if (brief == null) return null

            const isLast = index === Briefs.length - 1

            return (
              <BriefWrapper
                key={brief._key}
                briefCount={Briefs.length}
                isLast={isLast}
                backgroundColor={(brief.color as Color) ?? "purple"}
                isRowLayout={isRowLayout}
              >
                <TextWrapper isRowLayout={isRowLayout}>
                  <div className="tag">
                    <p className="tag-name">{brief.category?.CategoryName}</p>
                  </div>
                  <h3>{brief.title}</h3>
                  <p>{brief.description}</p>
                  {brief.link?.url && (
                    <MyLink
                      url={brief.link.url}
                      className="theme-btn block white"
                    >
                      {brief.link.linkText}
                    </MyLink>
                  )}
                  {brief.icon?.asset?.fluid && (
                    <StyledAbsoluteContainer
                      bottom="-5px"
                      isRowLayout={isRowLayout}
                    >
                      <img
                        src={brief.icon.asset.fluid.src}
                        alt={brief.icon?.altText as string}
                      />
                    </StyledAbsoluteContainer>
                  )}
                </TextWrapper>
              </BriefWrapper>
            )
          })}
        </Wrapper>
      )}
    </>
  )
}

export default ServicesBrief
