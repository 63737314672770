import styled from "styled-components"

interface Props {
  reverse?: boolean | null
  leftRatio?: number | null
  rightRatio?: number | null
  imageSize?: number | null
  topBottomSpace?: number | null
}

export default styled.div<Props>`
  display: grid;
  grid-gap: 15rem;
  grid-template-columns: ${props => props.leftRatio || 1}fr ${props =>
      props.rightRatio || 1}fr;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: ${props => props.topBottomSpace || 100}px auto;

  @media (max-width: 1200px) {
    grid-gap: 10rem;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    margin: 80px auto;
  }

  @media (max-width: 1000px) {
    margin: 40px auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: medium;
    font-size: 1.5em;
    margin-bottom: 1.5rem;
    color: var(--red);
  }

  p {
    margin-bottom: 3rem;
    font-family: thin;
    color: var(--red);
  }

  a {
    font-family: medium;
    color: var(--red);

    &.inline {
      margin-bottom: 1rem;
      font-size: 1.6rem;

      :hover {
        transform: translateX(15px);
      }
    }

    &.block {
      margin-bottom: 1rem;
    }
  }

  strong {
    font-family: medium;
    color: var(--red);
  }

  & > div.gatsby-image-wrapper {
    @media (min-width: 1000px) {
      transform-origin: center left;
      ${props =>
        props.reverse &&
        `
        grid-column: 1/2;
        grid-row: 1/2;
        transform-origin: center right;
      `}
      transform: scale(${props => (props.imageSize ? props.imageSize : 1)});
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 1.8rem;
      margin-bottom: 1rem;
      color: var(--red);
      font-family: thin;

      &::before {
        content: "";
        position: absolute;
        left: 0.2rem;
        top: 0.8rem;
        display: block;
        width: 0.6rem;
        height: 0.6rem;
        background-color: var(--red);
        border-radius: 1rem;
        /* opacity: 0.8; */
      }
    }
  }
`
