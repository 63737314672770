import React from "react";
import MyLink from "../shared/sub/myLink";

import BlogBlock from "./blogBlockStyle";

import { CategoryTag, Container } from "@util/standard";
import { SanityBlogs } from "@graphql-types";
import { Color } from "@util/types";
import { setBlogSlug } from "@util/helpers";
import { Image } from "@global";

interface Props {
  blog: SanityBlogs;
}

const Blog = ({ blog }: Props) => {
  if (blog == null) return null;

  const firstCategory = blog.categories && blog.categories.length > 0 && blog.categories[0];
  const blogSlug = setBlogSlug(blog);

  return (
    <MyLink className="image-link" url={blogSlug}>
      <BlogBlock hasAuthor={blog.author !== null && blog?.author !== ""}>
        {blog?.featureImage && <Image data={blog?.featureImage} />}
        <Container width="100%" justifyContent="space-between">
          {firstCategory && (
            <CategoryTag backgroundColor={firstCategory.colorList as Color} className="category">
              {firstCategory.CategoryName}
            </CategoryTag>
          )}
        </Container>
        <Container flexDirection="column" justifyContent="space-between" height="37%">
          <div>
            <h3>{blog.title}</h3>
            <p>{blog.excerpt}</p>
          </div>
          <div>
            <p className="author">
              {blog.publishdate} | By {blog.author ?? "Ben Pauley"}
            </p>
          </div>
        </Container>
      </BlogBlock>
    </MyLink>
  );
};

export default Blog;
