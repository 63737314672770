import { SanityBlogs } from "@graphql-types";

export const formatValue = (value: number) => {
  const formatedValue = `$ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  return formatedValue;
};

export const parseValue = (value: string) => {
  const parseValue = value.replace(/\$\s?|(,*)/g, "").replace(/\s/g, "");
  return parseValue;
};

export const formatPublishDate = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6);
  return day + "-" + month + "-" + year;
};

export const isBrowser = () => typeof window !== "undefined";

export const setBlogSlug = (blog: SanityBlogs) => {
  const { categories, slug } = blog;
  const firstCategory = categories?.[0];
  if (!firstCategory || !slug) return "";
  if (firstCategory?.categorySlug?.current && slug?.current) {
    return `/blog/${firstCategory.categorySlug.current}/${slug.current}`;
  }

  if (slug?.current) return `/blog/${slug?.current}`;

  return "";
};

/** Returns a string with only valid classes */
export const mergeClassNames = (...classes: (string | null | undefined | false)[]) =>
  [...classes].filter(Boolean).join(" ");

export const numberToCurrency = (num: number | string | undefined, showSymbol = true) => {
  const val = ["string", "number"].includes(typeof num) ? num : 0;
  const numVal = typeof val === "string" ? stringToNumber(val) : val;

  return numVal!.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "USD",
    style: showSymbol ? "currency" : undefined,
  });
};

export const formattedNumber = (num: number | string | undefined, fractionDigits = 2) => {
  const val = ["string", "number"].includes(typeof num) ? num : 0;
  const numVal = typeof val === "string" ? stringToNumber(val) : val;
  return numVal!.toLocaleString("en-US", {
    maximumFractionDigits: fractionDigits,
  });
};

export const stringToNumber = (val: string | undefined) => {
  if (!val) return 0;
  return parseFloat(val.replace(/,/g, ""));
};

export const anyValueToNumber = (val: any) => {
  if (!val) return 0;
  const newVal = Number(val);
  if (isNaN(newVal)) return 0;
  return newVal;
};

export const getCssVar = (name: CssVarName, fallback?: string | number) => {
  const formattedName = name
    .split(/(?=[A-Z])/)
    .join("-")
    .toLowerCase();

  if (typeof fallback !== "undefined") return `var(--${formattedName}, ${fallback})`;
  return `var(--${formattedName})`;
};
