import React, { useEffect } from "react";
import styled from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import ContentBlock from "@components/shared/sub/contentBlock";
import { Maybe, SanityFormBlock } from "@graphql-types";
import { Helmet } from "react-helmet";

interface Props {
  data: Maybe<SanityFormBlock> | undefined;
}

function FormBlock({ data }: Props) {
  if (data == null) return null;

  useEffect(() => {
    if (data.formId) {
      const script = document.createElement("script");
      script.src = `https://lateralpartners.activehosted.com/f/embed.php?id=${data.formId}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const { image, description, formTitle, formId, typeformEmbed } = data;

  return (
    <ContentSection>
      <Helmet>
        <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      <div className="container">
        {image && <Img fluid={image?.asset?.fluid as FluidObject} alt={image.altText as string} />}
        {description && !image && (
          <div className="description">
            <ContentBlock blocks={description._rawContent} />
          </div>
        )}
        <div className="right">
          {description && image && (
            <div className="description">
              <ContentBlock blocks={description._rawContent} />
            </div>
          )}

          {formTitle && <h3 className="form-title">{formTitle}</h3>}

          {formId && <div className={`_form_${formId}`}></div>}
          {typeformEmbed?.code && (
            <div dangerouslySetInnerHTML={{ __html: typeformEmbed.code }}></div>
          )}
        </div>
      </div>
    </ContentSection>
  );
}

export default FormBlock;

const ContentSection = styled.section`
  margin: 8rem 0;

  .container {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 4%;
    max-width: 1380px;

    h2 {
      color: var(--red);
    }
    li {
      list-style: disc;
    }
    @media only screen and (max-width: 976px) {
      grid-template-columns: 1fr;
    }
    color: var(--red);

    p {
      margin-bottom: 1.5rem;
    }
    .right {
      .description {
        color: var(--red);

        p {
          margin-bottom: 1.5rem;
        }

        strong {
          font-family: medium;
        }
      }

      h3.form-title {
        color: var(--red);
        font-family: medium;
        margin: 2rem 0;
        font-size: 1.8rem;
      }

      form {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
`;
