import React, { Dispatch, ReactNode, SetStateAction, useEffect } from "react"
import styled from "styled-components"

import { IconClose } from "@util/assets"

import { useEventListener } from "@util/hooks"
import { Overlay } from "@util/standard"
import { isBrowser } from "@util/helpers"
import { TABLET_BREAKPOINT } from "@util/constants"

interface Props {
  children: ReactNode
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

function Modal({ children, isOpen, setIsOpen }: Props) {
  if (children == null) return null

  useEffect(() => {
    if (!isBrowser()) return
    document.body.style.overflow = isOpen ? "hidden" : "initial"
  }, [isOpen])

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e && e.code === "Escape") {
      setIsOpen(false)
    }
  }

  useEventListener("keyup", handleKeyUp)

  if (isOpen) {
    return (
      <ModalWrapper>
        <Overlay zIndex={0} />
        <ModalChildWrapper>
          {children}
          <CloseWrapper onClick={() => setIsOpen(false)}>
            <IconClose />
          </CloseWrapper>
        </ModalChildWrapper>
      </ModalWrapper>
    )
  }
  return null
}

export default Modal

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 10;
`

const ModalChildWrapper = styled.div`
  position: absolute;
  z-index: 11;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`

const CloseWrapper = styled.div`
  position: absolute;
  filter: invert(1);
  cursor: pointer;
  top: -50px;
  right: 0px;
`
