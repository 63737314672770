import React, { ReactNode, useEffect } from "react"
import { Swiper } from "swiper/react"
import SwiperCore, {
  Pagination,
  Controller,
  Navigation,
  SwiperOptions,
} from "swiper"
// update swiper styling here
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
interface Props extends Swiper {
  children: ReactNode
}

export default function Carousel(props: Props) {
  useEffect(() => {
    SwiperCore.use([Controller, Pagination, Navigation])
  }, [])
  // elements such as dots or next/prev are added through the modules property
  return (
    <Swiper {...props}>
      {/* in order for the children to show you must wrap every child with "SwiperSlide" component */}
      {props.children}
    </Swiper>
  )
}
