import React, { useEffect } from "react"
import styled from "styled-components"

const ActiveHostedForm = () => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://lateralpartners.activehosted.com/f/embed.php?id=6"
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <NewsLetterStyle>
      <div className="_form_6"></div>
    </NewsLetterStyle>
  )
}

export default ActiveHostedForm

const NewsLetterStyle = styled.section`
  ._form_6 form {
    padding: 0 !important;
    margin-top: 0 !important;
    width: 100% !important;
  }
`
