import React from "react";
import ContentBlock from "./contentBlock";
import CTAStyle from "./ctaStyle";
import { Maybe, SanityFlightImage, SanityMyBlock } from "@graphql-types";
import { Image } from "@global";

interface Props {
  text: Maybe<SanityMyBlock> | undefined;
  image: Maybe<SanityFlightImage> | undefined;
  reverse?: Maybe<boolean> | undefined;
  leftRatio?: Maybe<number> | undefined;
  rightRatio?: Maybe<number> | undefined;
  imageSize?: Maybe<number> | undefined;
  topBottomSpace?: Maybe<number> | undefined;
}

const CTA: React.FC<Props> = ({
  text,
  image,
  reverse,
  leftRatio,
  rightRatio,
  imageSize,
  topBottomSpace,
}) => {
  return (
    <CTAStyle
      leftRatio={leftRatio}
      rightRatio={rightRatio}
      reverse={reverse}
      imageSize={imageSize}
      topBottomSpace={topBottomSpace}
    >
      {text?._rawContent && <ContentBlock blocks={text._rawContent} />}
      {image && <Image data={image} />}
    </CTAStyle>
  );
};

export default CTA;
