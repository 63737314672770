import React from "react"
import styled from "styled-components"

import { SanityRelatedBlogs } from "@graphql-types"
import MyLink from "@components/shared/sub/myLink"
import BlogBlock from "../components/blogs/blogBlock"

interface Props {
  data: SanityRelatedBlogs
}

const RelatedBlogs = ({ data }: Props) => {
  if (data == null) return null

  const { blogs, title, link } = data

  return (
    <BlogsSectionStyle>
      <div className="container">
        <h3 className="section-title">{title}</h3>
        {link && link.url && (
          <MyLink className="theme-btn inline red" url={link?.url}>
            {link?.linkText}
          </MyLink>
        )}

        {blogs && (
          <div className="blogs">
            {blogs.map(blog => {
              if (blog == null) return null
              return <BlogBlock blog={blog} key={blog?._key} />
            })}
          </div>
        )}
      </div>
    </BlogsSectionStyle>
  )
}

export default RelatedBlogs

const BlogsSectionStyle = styled.section`
  background-color: transparent;
  margin: 8rem 0;

  .container {
    & > h3 {
      color: var(--red) !important;
      font-size: 2.4rem;
      display: inline-block;
      margin-bottom: 0px;
      min-width: 80px;
    }

    & > a {
      text-decoration: underline;
      display: inline-block;
    }

    .blogs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;
      margin-top: 2rem;

      @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }
  }
`
