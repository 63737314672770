import React from "react"

import { Tabs } from "antd"
import { Maybe, SanityContentTab } from "@graphql-types"
import { Container } from "@util/standard"
import styled from "styled-components"
import { colors, fontFamilies, TABLET_BREAKPOINT } from "@util/constants"
import ContentBlock from "@components/shared/sub/contentBlock"

interface Props {
  data: Maybe<SanityContentTab>[]
}

const { TabPane } = Tabs

const ContentWrapper = styled(Container)`
  width: 100%;
  justify-content: space-between;
  margin: 60px auto 0 auto;
  max-width: 916px;
  color: ${colors.maroon};

  h2 {
    color: ${colors.maroon};
    width: 23%;
    margin-right: 15px;
    font-size: 40px;
    line-height: 48px;
    font-family: ${fontFamilies.medium};
  }

  p {
    font-family: ${fontFamilies.thin};
    margin-bottom: 25px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;

    margin: auto;
    h2 {
      width: 80%;
      margin: 25px auto 40px auto;
    }
  }
`

function ContentTabs({ data }: Props) {
  if (data == null) return null
  return (
    <Container
      display="block"
      width="64%"
      tabletWidth="100%"
      margin="0 auto 125px auto"
      tabletMargin="50px auto"
    >
      <Tabs defaultActiveKey="1" centered>
        {data.map((tab, index) => {
          if (tab == null) return null
          return (
            <TabPane tab={tab.tabTitle} key={index + 1}>
              <ContentWrapper>
                <h2>{tab.content?.title}</h2>
                <Container width="63%" tabletWidth="80%" tabletMargin="auto">
                  <ContentBlock blocks={tab.content?.text?._rawContent} />
                </Container>
              </ContentWrapper>
            </TabPane>
          )
        })}
      </Tabs>
    </Container>
  )
}

export default ContentTabs
